/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --sidebar-width: 250px;
  --color-secondary-dark: hsl(210, 20%, 80%);
  --color-secondary-darker: hsl(210, 20%, 70%);
  --color-secondary-light: hsl(210, 20%, 95%);
  --color-contrast-higher: #1c1c21;
  --space-xxxs: 0.35rem;
}
